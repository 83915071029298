import * as S from 'apps/cliniko/style'
import * as _ from 'modules/util'
import { colorStyles } from './color-styles'

const Count = ({ color, count: countIn, float, max }) => {
  const countIsOverMax = max ? _.toNumber(countIn) > max : false
  const count = countIsOverMax ? `${max}+` : countIn
  return (
    <div css={styles({ color, countIsOverMax, float })}>
      {count} <span css={S.visuallyHidden}>items</span>
    </div>
  )
}

const styles = ({ color, float }) => [
  S.space({ px: 1, py: 0.5 }),
  {
    display: 'inline-grid',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: S.unit(3),
    backgroundColor: color.includes('fill') && !float ? 'white' : S.colors.pink(),
    color: color.includes('fill') && !float ? colorStyles[color].backgroundColor : 'white',
    fontWeight: 500,
    borderRadius: S.rem(12),
    fontSize: S.rem(13),
    lineHeight: S.unit(2),
    flex: '0 0 auto',
  },
  float
    ? {
        position: 'absolute',
        top: S.unit(-1),
        right: S.unit(-1),
      }
    : S.space({ ml: 2, mr: -2 }),
]

export default Count
