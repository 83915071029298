import * as _ from 'modules/util'
import * as R from 'ramda'
import { maxWidthQuery, minMaxWidthQuery, minWidthQuery } from './media'

export const makeBreakpoint = ({ allBreakpoints, media }) => {
  const breakpoint = R.curry((bp, value) => {
    if (bp === 'never') {
      return value
    }
    if (_.isNumber(bp)) {
      return { [`@media screen and ${bp < 0 ? maxWidthQuery(bp) : minWidthQuery(bp)}`]: value }
    }
    if (_.isString(bp)) {
      return { [media[bp]]: value }
    }
    if (_.isArray(bp) && bp.every(_.isNumber)) {
      return { [`@media screen and ${minMaxWidthQuery(bp)}`]: value }
    }
    if (_.isArray(bp) && bp.every(_.isString)) {
      return {
        [`@media screen and ${minMaxWidthQuery([allBreakpoints[bp[0]], allBreakpoints[bp[1]]])}`]:
          value,
      }
    }
  })

  const breakpoints = {
    below: R.curry((bp, value) => {
      if (bp === 'never') return

      return breakpoint(
        _.thru(
          bp,
          R.ifElse(_.isString, R.pipe(_.capitalizeFirstLetter, R.concat('below')), R.negate)
        ),
        value
      )
    }),
    on: breakpoint,
  }

  for (const query in media) {
    breakpoints[query] = breakpoint(query)
  }

  return breakpoints
}
