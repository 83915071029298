import {
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react'
import * as S from 'apps/cliniko/style'
import * as _ from 'modules/util'
import { useRef, useState } from 'react'
import ActionsContext from './context'

const ActionsProvider = ({ children, ...props }) => {
  const actions = useActions(props)
  return <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
}

const useActions = ({ compact, isOpen: isOpenIn = false, placement = 'bottom-end' } = {}) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [isOpen, setIsOpen] = useState(isOpenIn)
  const elementsRef = useRef([])
  const labelsRef = useRef([])

  const floatingData = useFloating({
    middleware: [
      offset(S.units(1)),
      flip({ padding: S.units(3) }),
      shift({ padding: S.units(1) }),
      size({
        apply: ({ elements, availableHeight }) => {
          elements.floating.style.maxHeight = S.rem(availableHeight)
        },
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
  })

  const { context } = floatingData
  const click = useClick(context)
  const dismiss = useDismiss(context)
  const listNavigation = useListNavigation(context, {
    activeIndex,
    focusItemOnOpen: true,
    listRef: elementsRef,
    onNavigate: setActiveIndex,
  })
  const role = useRole(context, { role: 'dialog' })
  const typeahead = useTypeahead(context, {
    activeIndex,
    enabled: isOpen,
    listRef: labelsRef,
    onMatch: _.when(isOpen, setActiveIndex),
  })
  const interactions = useInteractions([click, dismiss, listNavigation, role, typeahead])

  return {
    activeIndex,
    compact,
    isOpen,
    elementsRef,
    labelsRef,
    setActiveIndex,
    setIsOpen,
    ...interactions,
    ...floatingData,
  }
}

export default ActionsProvider
