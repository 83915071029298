import {
  baselineOffset,
  borderRadius,
  flexWrap,
  negativeBaselineOffset,
} from 'modules/style/helpers'
import * as R from 'ramda'
import { breakpoint } from './breakpoint'
import colors from './colors'
import { media } from './media'
import { space, unit } from './space'

export * from 'modules/style/helpers'

export const headerCellBadgeContainer = [
  baselineOffset,
  flexWrap(),
  breakpoint.belowMaxContent([negativeBaselineOffset, space.mt(1)]),
]

export const pagePaddingX = {
  ...space.px(2),
  [media.medium]: space.px(4),
}

export const pagePaddingY = {
  ...space({ pt: 2, pb: 3 }),
  [media.medium]: space.pb(4),
}

export const pagePadding = R.mergeDeepLeft(pagePaddingX, pagePaddingY)

export const scrollbar = {
  '&::-webkit-scrollbar': {
    width: unit(1),
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: [
      `inset 0 0 0 1px ${colors.neutral(-2)}`,
      `inset 0 0 0 ${unit(0.5)} ${colors.neutral()}`,
    ].join(', '),
    borderRadius,
  },

  '&::-webkit-scrollbar-thumb': {
    boxShadow: `inset 0 0 0 ${unit(0.5)} ${colors.neutral(-4)}`,
    borderRadius,
  },
}
