import ArchivedAlert from './archived-alert'
import Loader from './loader'
import Page from './page'
import PageActions from './page-actions'
import PageHeader from './page-header'
import PageSections from './page-sections'

Page.ArchivedAlert = ArchivedAlert
Page.Actions = PageActions
Page.Header = PageHeader
Page.Loader = Loader
Page.Sections = PageSections

export default Page
