import * as S from 'apps/cliniko/style'
import Callout from 'components/callout'
import Prose from 'components/prose'
import * as Time from 'modules/time'

const ArchivedAlert = ({ archivedAt, resourceLabel, unarchive, ...props }) => {
  const unarchiveAction = !!unarchive && {
    children: <>Unarchive {resourceLabel}</>,
    isLoading: unarchive.isPending,
    onClick: unarchive.mutate,
  }

  return (
    <Callout actions={unarchiveAction} css={S.space.mb(2)} type="error" {...props}>
      <Prose>
        This {resourceLabel} was archived on {Time.formatDateTime(archivedAt)}.
      </Prose>
    </Callout>
  )
}

export default ArchivedAlert
