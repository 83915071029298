import { Button } from './button'
import { Content } from './content'
import { Group } from './group'
import { TooltipProvider as Tooltip } from './use-tooltip'

Tooltip.Button = Button
Tooltip.Content = Content
Tooltip.Group = Group

export default Tooltip
