import * as S from 'apps/cliniko/style'

export const colorStyles = {
  'pink-fill': {
    color: 'white',
    backgroundColor: S.colors.pink(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.pink(-1),
    },
    '&:active': {
      backgroundColor: S.colors.pink(-2),
    },
    '&:disabled': {
      backgroundColor: S.colors.grey(15),
    },
  },
  'pink-stroke': {
    color: S.colors.pink(),
    backgroundColor: 'transparent',
    ...S.insetBorder({ color: S.colors.pink() }),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.pink(9),
    },
    '&:active': {
      backgroundColor: S.colors.pink(8),
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      ...S.insetBorder({ color: S.colors.grey(11) }),
    },
  },
  'pink-soft': {
    color: S.colors.pink(),
    backgroundColor: S.colors.pink(9),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.pink(8),
    },
    '&:active': {
      backgroundColor: S.colors.pink(7),
    },
    '&:disabled': {
      backgroundColor: S.colors.grey(15),
    },
  },
  'pink-borderless': {
    color: S.colors.pink(),
    backgroundColor: 'transparent',
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.pink(9),
    },
    '&:active': {
      backgroundColor: S.colors.pink(8),
    },
    '&:disabled, &:disabled:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  'neutral-stroke': {
    color: S.colors.neutral(-13),
    backgroundColor: 'transparent',
    ...S.insetBorder({ color: S.colors.neutral(-6) }),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.neutral(),
    },
    '&:active': {
      backgroundColor: S.colors.neutral(-1),
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      ...S.insetBorder({ color: S.colors.grey(11) }),
    },
  },
  'neutral-soft': {
    color: S.colors.neutral(-13),
    backgroundColor: S.colors.neutral(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.neutral(-1),
    },
    '&:active': {
      backgroundColor: S.colors.neutral(-2),
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
  'neutral-borderless': {
    color: S.colors.neutral(-13),
    backgroundColor: 'transparent',
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.neutral(),
    },
    '&:active': {
      backgroundColor: S.colors.neutral(-2),
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
  white: {
    color: S.colors.grey(),
    backgroundColor: 'white',
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.grey(15),
    },
    '&:active': {
      backgroundColor: S.colors.grey(14),
    },
    '&:disabled': {
      backgroundColor: 'white',
    },
  },
  'grey-borderless': {
    color: S.colors.grey(),
    backgroundColor: 'transparent',
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.grey(15),
    },
    '&:active': {
      backgroundColor: S.colors.grey(14),
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
  'grey-fill': {
    color: 'white',
    backgroundColor: S.colors.grey(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.grey(-1),
    },
    '&:active': {
      backgroundColor: S.colors.grey(-2),
    },
    '&:disabled': {
      color: S.colors.grey(11),
      backgroundColor: S.colors.grey(15),
    },
  },
  'grey-fill-alt': {
    color: 'white',
    backgroundColor: S.colors.grey(3),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.grey(2),
    },
    '&:active': {
      backgroundColor: S.colors.grey(1),
    },
    '&:disabled': {
      color: S.colors.grey(11),
      backgroundColor: S.colors.grey(15),
    },
  },
  'quiet-borderless': {
    color: S.colors.grey(5),
    backgroundColor: 'transparent',
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.grey(15),
    },
    '&:active': {
      backgroundColor: S.colors.grey(14),
    },
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
  'blue-fill': {
    color: 'white',
    backgroundColor: S.colors.blue(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.blue(-1),
    },
    '&:active': {
      backgroundColor: S.colors.blue(-2),
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: S.colors.grey(15),
    },
  },
  'blue-fill-alt': {
    color: 'white',
    backgroundColor: S.colors.blue(-2),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.blue(-3),
    },
    '&:active': {
      backgroundColor: S.colors.blue(-4),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.blue(-1),
      backgroundColor: S.colors.blue(1),
    },
  },
  'blue-soft': {
    color: S.colors.blue(-1),
    backgroundColor: S.colors.blue(12),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.blue(11),
    },
    '&:active': {
      backgroundColor: S.colors.blue(10),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.grey(11),
      backgroundColor: S.colors.grey(15),
    },
  },
  'blue-soft-stroke': {
    color: S.colors.blue(-1),
    backgroundColor: S.colors.blue(12),
    ...S.insetBorder({ color: S.colors.blue(8) }),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.blue(11),
    },
    '&:active': {
      backgroundColor: S.colors.blue(10),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.blue(8),
      backgroundColor: S.colors.blue(13),
      ...S.insetBorder({ color: S.colors.blue(8) }),
    },
  },
  'blue-borderless': {
    color: S.colors.blue(-1),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.blue(11),
    },
    '&:active': {
      backgroundColor: S.colors.blue(10),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.blue(8),
      backgroundColor: 'transparent',
    },
  },
  'purple-fill': {
    color: 'white',
    backgroundColor: S.colors.purple(2),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.purple(1),
    },
    '&:active': {
      backgroundColor: S.colors.purple(),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.purple(10),
      backgroundColor: S.colors.purple(13),
    },
  },
  'purple-fill-alt': {
    color: 'white',
    backgroundColor: S.colors.purple(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.purple(-1),
    },
    '&:active': {
      backgroundColor: S.colors.purple(-2),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.purple(5),
      backgroundColor: S.colors.purple(1),
    },
  },
  'red-fill': {
    color: 'white',
    backgroundColor: S.colors.red(0),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.red(-1),
    },
    '&:active': {
      backgroundColor: S.colors.red(-2),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.red(8),
      backgroundColor: S.colors.red(11),
    },
  },
  'red-soft': {
    color: S.colors.red(),
    backgroundColor: S.colors.red(11),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.red(10),
    },
    '&:active': {
      backgroundColor: S.colors.red(9),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.red(8),
      backgroundColor: S.colors.red(11),
    },
  },
  'lavender-fill': {
    color: 'white',
    backgroundColor: S.colors.lavender(-6),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.lavender(-5),
    },
    '&:active': {
      backgroundColor: S.colors.lavender(-4),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.lavender(-5),
      backgroundColor: S.colors.lavender(-3),
    },
  },
  'lavender-soft': {
    color: S.colors.lavender(-9),
    backgroundColor: S.colors.lavender(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.lavender(-1),
    },
    '&:active': {
      backgroundColor: S.colors.lavender(-2),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.lavender(-5),
      backgroundColor: S.colors.lavender(-3),
    },
  },
  'orange-fill': {
    color: 'white',
    backgroundColor: S.colors.orange(-6),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.orange(-5),
    },
    '&:active': {
      backgroundColor: S.colors.orange(-4),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.orange(-5),
      backgroundColor: S.colors.orange(-3),
    },
  },
  'orange-soft': {
    color: S.colors.orange(-8),
    backgroundColor: S.colors.orange(5),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.orange(4),
    },
    '&:active': {
      backgroundColor: S.colors.orange(3),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.orange(),
      backgroundColor: S.colors.orange(3),
    },
  },
  'red-soft-stroke': {
    color: S.colors.red(),
    backgroundColor: S.colors.red(11),
    ...S.insetBorder({ color: S.colors.red() }),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.red(10),
    },
    '&:active': {
      backgroundColor: S.colors.red(9),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.red(8),
      backgroundColor: S.colors.red(11),
      ...S.insetBorder({ color: S.colors.red(8) }),
    },
  },
  'red-borderless': {
    color: S.colors.red(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.red(11),
    },
    '&:active': {
      backgroundColor: S.colors.red(10),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.red(8),
      backgroundColor: 'transparent',
    },
  },
  'yellow-fill': {
    color: 'white',
    backgroundColor: S.colors.yellow(-10),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.yellow(-11),
    },
    '&:active': {
      backgroundColor: S.colors.yellow(-12),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.yellow(-5),
      backgroundColor: S.colors.yellow(3),
    },
  },
  'yellow-soft': {
    color: S.colors.yellow(-12),
    backgroundColor: S.colors.yellow(2),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.yellow(1),
    },
    '&:active': {
      backgroundColor: S.colors.yellow(0),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.yellow(-5),
      backgroundColor: S.colors.yellow(3),
    },
  },
  'yellow-soft-stroke': {
    color: S.colors.yellow(-12),
    backgroundColor: S.colors.yellow(2),
    ...S.insetBorder({ color: S.colors.yellow(-12) }),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.yellow(1),
    },
    '&:active': {
      backgroundColor: S.colors.yellow(0),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.yellow(-5),
      backgroundColor: S.colors.yellow(3),
      ...S.insetBorder({ color: S.colors.yellow(-5) }),
    },
  },
  'yellow-borderless': {
    color: S.colors.yellow(-12),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.yellow(2),
    },
    '&:active': {
      backgroundColor: S.colors.yellow(1),
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: 'transparent',
    },
  },
  'green-fill': {
    color: 'white',
    backgroundColor: S.colors.green(),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.green(-1),
    },
    '&:active': {
      backgroundColor: S.colors.green(-2),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.green(6),
      backgroundColor: S.colors.green(11),
    },
  },
  'green-fill-alt': {
    color: 'white',
    backgroundColor: S.colors.grey(3),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.grey(2),
    },
    '&:active': {
      backgroundColor: S.colors.grey(1),
    },
    '&:disabled': {
      backgroundColor: S.colors.grey(15),
    },
  },
  'green-soft': {
    color: S.colors.green(-1),
    backgroundColor: S.colors.green(11),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.green(10),
    },
    '&:active': {
      backgroundColor: S.colors.green(9),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.green(6),
      backgroundColor: S.colors.green(11),
    },
  },
  'green-soft-stroke': {
    color: S.colors.green(-1),
    backgroundColor: S.colors.green(11),
    ...S.insetBorder({ color: S.colors.green(-1) }),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.green(10),
    },
    '&:active': {
      backgroundColor: S.colors.green(9),
    },
    '&:disabled, &:disabled:hover': {
      color: S.colors.green(6),
      backgroundColor: S.colors.green(11),
      ...S.insetBorder({ color: S.colors.green(6) }),
    },
  },
  'green-borderless': {
    color: S.colors.green(-1),
    '&:hover, &[aria-expanded="true"]': {
      backgroundColor: S.colors.green(11),
    },
    '&:active': {
      backgroundColor: S.colors.green(10),
    },
    '&:disabled, &:disabled:hover': {
      backgroundColor: 'transparent',
    },
  },
}
