import * as S from 'apps/cliniko/style'
import Button from 'components/button'
import Loader from 'components/loader'
import Tooltip from 'components/tooltip'
import * as _ from 'modules/util'
import { forwardRef } from 'react'

const CircleButtonWithoutTooltip = forwardRef(
  ({ children, compact, icon, label, notification, ...props }, ref) => {
    const smallLoader = <Loader inherit size="small" />
    return (
      <Button
        aria-label={label || children}
        css={styles({ compact, isNumber: Boolean(children), notification })}
        icon={icon}
        loader={smallLoader}
        ref={ref}
        {...props}>
        {!!(_.isPresent(children) || (!icon && label)) && (
          <span aria-hidden css={{ ...S.absoluteCenter, top: `calc(50% + ${S.rem(1)})` }}>
            {children ?? _.unless(icon, label)}
          </span>
        )}
      </Button>
    )
  }
)

const CircleButtonWithTooltip = forwardRef(({ compact, label, tooltip, ...props }, ref) => (
  <Tooltip compact={compact} {...tooltip}>
    <Tooltip.Button as={CircleButtonWithoutTooltip} label={label} ref={ref} {...props} />
    <Tooltip.Content>{label}</Tooltip.Content>
  </Tooltip>
))

const CircleButton = forwardRef(({ tooltip, ...props }, ref) =>
  tooltip ? (
    <CircleButtonWithTooltip ref={ref} tooltip={tooltip === true ? {} : tooltip} {...props} />
  ) : (
    <CircleButtonWithoutTooltip ref={ref} {...props} />
  )
)

const styles = ({ compact, isNumber, notification }) => [
  S.space.p(isNumber ? (compact ? 2 : 2.5) : compact ? 1 : 1.5),
  S.space(['height', 'width'], compact ? 4 : 5),
  {
    borderRadius: '50%',
    position: 'relative',
    svg: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  notification && {
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: S.unit(0.25),
      right: S.unit(-0.25),
      width: compact ? S.rem(9) : S.unit(1.5),
      height: compact ? S.rem(9) : S.unit(1.5),
      borderRadius: '50%',
      backgroundColor: S.colors.pink(),
    },
  },
]

export default CircleButton
