import * as S from 'apps/cliniko/style'

const Swatch = ({ color }) => <div css={styles({ color })} />

const styles = ({ color }) => ({
  ...S.space({ width: 1.5, height: 1.5, ml: -1, mr: 1 }),
  flex: '0 0 auto',
  borderRadius: '50%',
  backgroundColor: color,
})

export default Swatch
