import * as S from 'apps/cliniko/style'
import * as _ from 'modules/util'

const Highlight = ({ search, children, ...props }) => {
  const searchIndex = children?.toLowerCase().indexOf(search?.toLowerCase())
  if (_.isBlank(search) || _.isBlank(children) || searchIndex === -1) return children

  const initialString = children.substring(0, searchIndex)
  const matchedString = children.substring(searchIndex, searchIndex + search.length)
  const remainingString = children.substring(searchIndex + search.length)

  return (
    <span css={{ position: 'relative' }}>
      {!!initialString && <span css={plainStyles}>{initialString}</span>}
      <mark css={markStyles} {...props}>
        {matchedString}
      </mark>
      {!!remainingString && <span css={plainStyles}>{remainingString}</span>}
    </span>
  )
}

const plainStyles = {
  position: 'relative',
  zIndex: 2,
}

export const markStyles = {
  position: 'relative',
  zIndex: 1,
  color: 'inherit',
  background: 'none',
  fontSize: 'inherit',
  lineHeight: 'inherit',

  '&:after': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: S.em(8),
    left: S.rem(-2),
    width: `calc(100% + ${S.rem(2)})`,
    height: S.em(12),
    background: S.colors.yellow(2),
  },
}

export default Highlight
