import * as _ from 'modules/util'
import * as W from 'modules/window'
import * as R from 'ramda'
import { maxWidthQuery, minMaxWidthQuery, minWidthQuery } from './media'

export const makeMatchBreakpoint = ({ allBreakpoints }) => {
  const getBreakpointValue = R.prop(R.__, allBreakpoints)

  const getBreakpointWidth = R.cond([
    [_.isString, getBreakpointValue],
    [_.isNumber, R.identity],
    [_.isArray, R.map(R.either(getBreakpointValue, R.identity))],
  ])

  const makeUseBreakpointHelper = template => R.pipe(getBreakpointWidth, template, W.useMatchMedia)

  const useIsPastBreakpoint = makeUseBreakpointHelper(minWidthQuery)
  const useIsBelowBreakpoint = makeUseBreakpointHelper(maxWidthQuery)
  const useIsBetweenBreakpoints = makeUseBreakpointHelper(minMaxWidthQuery)

  return { useIsPastBreakpoint, useIsBelowBreakpoint, useIsBetweenBreakpoints }
}
