// These are also used in app/assets/stylesheets/application-redesign/_breakpoints.sass
export const small = 400
export const form = 560
export const maxContent = 696
export const medium = 769
export const column = 850
export const large = 1024
export const xlarge = 1148

export const allBreakpoints = { small, form, maxContent, medium, column, large, xlarge }

export const inBetweenBreakpoints = [
  ['small', 'medium'],
  ['form', 'maxContent'],
  ['maxContent', 'medium'],
  ['maxContent', 'large'],
  ['maxContent', 'xlarge'],
  ['small', 'large'],
  ['medium', 'large'],
  ['column', 'large'],
  ['medium', 'xlarge'],
]
