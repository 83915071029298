import lodashRound from 'lodash/round'
import * as R from 'ramda'

export const divideBy = R.flip(R.divide)

export const subtractFrom = x => R.subtract(x)

export const toFixed = R.curry((precision, number) => number.toFixed(precision))

export const round = R.curry((precision, number) => lodashRound(number, precision))

export const convertNegativeZeroToZero = number => (Object.is(-0, number) ? 0 : number)

export const weightedGeometricMean = R.curry((weights, numbers) =>
  Math.pow(
    numbers.map((number, index) => number ** weights[index]).reduce(R.multiply, 1),
    1 / R.reduce(R.add, 0, weights)
  )
)
