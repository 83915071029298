import {
  add,
  addDays,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  endOfDay,
  endOfMonth as endOfMonthFn,
  endOfToday,
  endOfTomorrow,
  endOfWeek as endOfWeekFn,
  formatDistanceToNow,
  format as formatWithoutTZ,
  isBefore as isBeforeDate,
  isDate,
  isFuture,
  isPast,
  isSameDay as isSameDayFn,
  startOfDay,
  startOfMonth as startOfMonthFn,
  startOfToday,
  startOfTomorrow,
  startOfWeek as startOfWeekFn,
  subDays,
  subMonths,
} from 'date-fns'
import { enAU } from 'date-fns/locale/en-AU'
import { enUS } from 'date-fns/locale/en-US'
import { formatInTimeZone as formatInTZ, fromZonedTime, toDate } from 'date-fns-tz'
import { pluralize } from 'modules/util/strings'
import * as R from 'ramda'

export {
  add,
  differenceInSeconds,
  endOfDay,
  endOfToday,
  endOfTomorrow,
  startOfDay,
  startOfToday,
  startOfTomorrow,
}

export const formats = {
  date: 'd MMM yyyy',
  dateWithDay: 'E d MMM yyyy',
  jsDate: 'yyyy-MM-dd',
  time: 'h:mmaaa',
  timeWithTimeZone: 'h:mmaaa (z)',
}

export const daysAgo = days => subDays(endOfToday(), days)

export const daysFromNow = days => days && addDays(now(), days)

export const daysFromNowToString = date => {
  if (!date) return ''

  const hoursLeft = differenceInHours(parseDate(date), now())

  if (hoursLeft <= 0) return '0 days'
  if (hoursLeft <= 12) return 'less than a day'

  const daysLeft = Math.round(hoursLeft / 24)
  return `${daysLeft} ${pluralize('day', daysLeft)}`
}

export const diffDays = R.curry((date, referenceDate) =>
  differenceInDays(parseDate(date), parseDate(referenceDate))
)

export const diffMonths = R.curry((date, referenceDate) =>
  differenceInMonths(parseDate(date), parseDate(referenceDate))
)

export const diffYears = R.curry((date, referenceDate) =>
  differenceInYears(parseDate(date), parseDate(referenceDate))
)

export const endOfMonth = (date = now()) => endOfMonthFn(date)

export const endOfWeek = (date = now(), options = { weekStartsOn: 1 }) => endOfWeekFn(date, options)

const getLocaleData = () =>
  ['en-BZ', 'en-FM', 'en-PW', 'en-US'].indexOf(window.locale) !== -1 ? enUS : enAU

export const formatInTimeZone = (date, { timeZone = window.timeZone, format } = {}) =>
  date && formatInTZ(parseDate(date, timeZone), timeZone, format, { locale: getLocaleData() })

const formatWithoutTimeZone = (date, { format } = {}) =>
  date && formatWithoutTZ(parseDate(date), format, { locale: getLocaleData() })

export const formatDate = (date, { format = formats.date, timeZone } = {}) =>
  formatInTimeZone(date, { format, timeZone })

export const formatDateOfBirth = date => formatWithoutTimeZone(date, { format: 'P' })

export const formatDateTime = (date, { dateFormat, timeFormat, timeZone } = {}) =>
  `${formatDate(date, { format: dateFormat, timeZone })}, ${formatTime(date, { format: timeFormat, timeZone })}`

export const formatTime = (date, { format = formats.time, timeZone } = {}) =>
  formatInTimeZone(date, { format, timeZone })

export const formatRelative = (date, { timeZone } = {}) => {
  if (!date) return

  const daysDifference = differenceInCalendarDays(now(), parseDate(date, { timeZone }))

  if (daysDifference === 0) return `today, ${formatTime(date, { timeZone })}`
  if (daysDifference === 1) return `yesterday, ${formatTime(date, { timeZone })}`
  if (daysDifference === -1) return `tomorrow, ${formatTime(date, { timeZone })}`

  return formatDateTime(date, { timeZone })
}

export const isBefore = R.curry((date, referenceDate) =>
  isBeforeDate(parseDate(date), parseDate(referenceDate))
)

export const isInThePast = date => !!date && isPast(parseDate(date))

export const isInTheFuture = date => !!date && isFuture(parseDate(date))

export const isSameDay = (date1, date2) =>
  !!date1 && !!date2 && isSameDayFn(parseDate(date1), parseDate(date2))

export const monthsAgo = months => subMonths(endOfToday(), months)

export const now = ({ timeZone = window.timeZone } = {}) => fromZonedTime(new Date(), timeZone)

const parseDate = (date, { timeZone = window.timeZone } = {}) =>
  isDate(date) ? date : toDate(date, timeZone)

export const startOfMonth = (date = now()) => startOfMonthFn(date)

export const startOfWeek = (date = now(), options = { weekStartsOn: 1 }) =>
  startOfWeekFn(date, options)

export const timeFromNowToString = (time, { includeSuffix: addSuffix } = {}) =>
  time ? formatDistanceToNow(parseDate(time), { addSuffix }) : ''

export const today = ({ format = formats.jsDate } = {}) => formatDate(now(), { format })

export const tomorrow = () => addDays(startOfToday(), 1)
