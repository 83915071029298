import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDelayGroup,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import * as S from 'apps/cliniko/style'
import { useId, useRef, useState } from 'react'
import TooltipContext from './context'

export const TooltipProvider = ({ children, ...options }) => {
  const tooltip = useTooltip(options)
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}

const useTooltip = ({
  compact,
  delay: delayIn = { open: 850, close: 150 },
  placement = 'bottom',
  toggle,
} = {}) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)
  const id = useId()

  const floatingData = useFloating({
    middleware: [
      offset(S.units(compact ? 1 : 2)),
      flip({ padding: S.unit(3) }),
      shift({ padding: S.units(1) }),
      arrow({ element: arrowRef }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
  })

  const { context } = floatingData

  const delay = useDelayGroup(context).delay || delayIn
  const click = useClick(context, { enabled: toggle })
  const focus = useFocus(context, { enabled: !toggle })
  const hover = useHover(context, {
    delay,
    enabled: !toggle,
    move: false,
    mouseOnly: true,
  })
  const dismiss = useDismiss(context, { referencePress: !toggle, referencePressEvent: 'click' })
  const interactions = useInteractions([click, dismiss, hover, focus])

  return {
    arrowRef,
    compact,
    delay,
    id,
    isOpen,
    setIsOpen,
    toggle,
    ...interactions,
    ...floatingData,
  }
}
