import * as Icon from 'components/icon'
import Notice from 'components/notice'
import * as _ from 'modules/util'

const Callout = ({ type = 'info', icon: iconIn, ...props }) => {
  const icon = _.unless(
    iconIn === 'none',
    _.cond(
      [!!iconIn, iconIn],
      [type === 'info', <Icon.InfoCircle />],
      [type === 'success', <Icon.Tick />],
      [<Icon.Triangle />]
    )
  )

  return <Notice icon={icon} type={type} {...props} />
}

export default Callout
