const Circle = ({ as: Component = 'div', width, ...props }) => (
  <Component css={styles({ width })} {...props} />
)

const styles = ({ width }) => ({
  display: 'inline-grid',
  justifyContent: 'center',
  alignItems: 'center',
  width,
  height: width,
  borderRadius: '50%',
})

export default Circle
