import * as S from 'apps/cliniko/style'
import Transition from 'components/transition'

const Loader = () => (
  <Transition
    css={styles}
    enter={[{ width: '100%', opacity: '100%' }, { opacity: '10%' }]}
    from={{ width: '0%', opacity: '0%' }}
    duration={3000}
  />
)

const styles = {
  background: S.colors.blue(2),
  height: S.unit(0.25),
  gridArea: 'banners',
  position: 'absolute',
  zIndex: S.zIndex('app', 'page-loader'),
  top: 0,
  left: 0,
}

export default Loader
