import * as S from 'apps/cliniko/style'
import CircleButton from 'components/circle-button'
import * as Icon from 'components/icon'
import * as _ from 'modules/util'
import { forwardRef, useContext } from 'react'
import ActionsContext from './context'

const Button = forwardRef(
  ({ as: Button = DefaultActionsButton, makeIcon = defaultMakeIcon, ...props }, refIn) => {
    const actions = useContext(ActionsContext)
    const ref = _.useMergeRefs([actions.refs.setReference, refIn])

    return (
      <Button
        css={S.breakpoint.betweenSmallAndMedium({ zIndex: S.zIndex('app', 'popup') })}
        icon={makeIcon(actions.isOpen)}
        ref={ref}
        {...props}
        {...actions.getReferenceProps(props)}></Button>
    )
  }
)

const DefaultActionsButton = forwardRef(({ color = 'neutral-borderless', ...props }, ref) => (
  <CircleButton {...props} color={color} ref={ref} />
))

const defaultMakeIcon = isOpen => (isOpen ? <Icon.X /> : <Icon.ThreeDots />)

export default Button
