import { FloatingArrow, useTransitionStyles } from '@floating-ui/react'
import * as S from 'apps/cliniko/style'
import Portal from 'components/portal'
import Prose from 'components/prose'
import * as _ from 'modules/util'
import { forwardRef, useContext } from 'react'
import TooltipContext from './context'

export const Content = forwardRef(({ children, ...propsIn }, refIn) => {
  const tooltip = useContext(TooltipContext)
  const ref = _.useMergeRefs([tooltip.refs.setFloating, refIn])

  const duration = { instant: 0, open: 200, close: 150 }

  const { isMounted, styles } = useTransitionStyles(tooltip.context, {
    duration: {
      open: tooltip.delay.isInstantPhase ? duration.instant : duration.open,
      close:
        tooltip.delay.currentId === tooltip.id
          ? duration.close
          : tooltip.delay.isInstantPhase
            ? duration.instant
            : duration.close,
    },
    initial: ({ side }) => ({
      opacity: 0,
      transform: _.cond(
        [side === 'top', `translateY(${S.unit(tooltip.compact ? 0.5 : 1)})`],
        [side === 'bottom', `translateY(${S.unit(tooltip.compact ? -0.5 : -1)}`],
        [side === 'left', `translateX(${S.unit(tooltip.compact ? 0.5 : 1)})`],
        [side === 'right', `translateX(${S.unit(tooltip.compact ? -0.5 : -1)}`]
      ),
    }),
  })

  const style = {
    position: tooltip.strategy,
    top: tooltip.y ?? 0,
    left: tooltip.x ?? 0,
    visibility: tooltip.x ? 'visible' : 'hidden',
    ...styles,
  }

  const props = {
    'aria-hidden': _.unless(tooltip.toggle, true),
    ref,
    role: _.when(tooltip.toggle, 'status'),
    style,
    ...propsIn,
    ...tooltip.getFloatingProps(propsIn),
  }

  return (
    !!isMounted && (
      <Portal>
        <Prose css={tooltipStyles({ compact: tooltip.compact })} {...props}>
          <FloatingArrow
            css={S.space(['height', 'width'], tooltip.compact ? 1 : 2)}
            context={tooltip.context}
            fill={S.colors.grey()}
            ref={tooltip.arrowRef}
          />
          {children}
        </Prose>
      </Portal>
    )
  )
})

const tooltipStyles = ({ compact }) => ({
  color: 'white',
  background: S.colors.grey(),
  width: 'fit-content',
  zIndex: S.zIndex('app', 'popup'),
  maxWidth: S.sizes.maxContent,
  bottom: 'unset',
  borderRadius: S.borderRadius,
  ...S.space({ py: compact ? 0.5 : 1, px: compact ? 1 : 2 }),
})
