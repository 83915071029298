import * as S from 'apps/cliniko/style'

const Group = props => (
  <li css={styles}>
    <ul {...props} />
  </li>
)

const styles = {
  '& + &': {
    position: 'relative',
    ...S.space.mt(2),

    '&:before': {
      content: '""',
      position: 'absolute',
      top: S.rem(-9),
      left: S.unit(3),
      width: `calc(100% - ${S.unit(6)})`,
      borderBottom: `1px solid ${S.colors.neutral(-1)}`,
    },
  },
}

export default Group
