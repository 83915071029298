import * as S from 'apps/cliniko/style'
import Circle from 'components/circle'
import * as Icon from 'components/icon'
import { colorStyles } from './color-styles'

const ToggleIcon = ({ selected, color: colorIn }) => {
  const colorIncludesFill = colorIn.includes('fill')
  const color = colorStyles[colorIn].color
  const iconColor = colorIncludesFill ? colorStyles[colorIn].backgroundColor : 'white'
  return selected ? (
    <Icon.Tick
      bold
      circle={styles({ color, colorIncludesFill, selected })}
      color={iconColor}
      width={S.unit(1.5)}
    />
  ) : (
    <Circle css={styles({ color, selected })} width={S.unit(3)} />
  )
}

const styles = ({ color, colorIncludesFill, selected }) => [
  { flex: '0 0 auto' },
  S.space({ mr: 2, ml: -2 }),
  selected
    ? {
        backgroundColor: color,
        boxShadow: 'none',
        'button[data-focus-visible-added] &': {
          backgroundColor: colorIncludesFill ? 'white' : S.colors.focus,
        },
      }
    : [
        S.insetBorder(),
        {
          'button[data-focus-visible-added] &': S.insetBorder({
            color: S.colors.focus,
            width: 2,
          }),
        },
      ],
]

export default ToggleIcon
