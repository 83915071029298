import * as S from 'apps/cliniko/style'
import Circle from 'components/circle'
import * as _ from 'modules/util'

export const makeIcon =
  ([Svg, BoldSvg]) =>
  ({ bold, color = 'currentColor', circle, width, ...propsIn }) => {
    const props = {
      color,
      role: 'presentation',
      ...(width ? { width } : {}),
      ...propsIn,
    }

    const svgStyles = !circle && { position: 'relative' }

    const svg =
      bold && BoldSvg ? <BoldSvg css={svgStyles} {...props} /> : <Svg css={svgStyles} {...props} />

    return circle ? (
      <Circle
        css={[{ position: 'relative', color }, S.insetBorder({ width: bold ? 2 : 1 }), circle]}
        width={width ? `calc(${_.isNumber(width) ? `${width}px` : width} * 2)` : S.units(4)}>
        {svg}
      </Circle>
    ) : (
      svg
    )
  }
