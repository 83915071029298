import { Global, css } from '@emotion/react'
import * as S from 'apps/cliniko/style'

const Loader = ({ inherit, size, ...props }) => {
  const dotStyle = dotStyles({ inherit, size })
  return (
    <span css={containerStyles({ size })} data-testid="loader" {...props}>
      <Global styles={[opacityBounceKeyframeStyles, blueBounceKeyframeStyles]} />
      <span css={dotStyle} />
      <span css={[dotStyle, { animationDelay: '200ms' }]} />
      <span css={[dotStyle, { animationDelay: '400ms' }]} />
    </span>
  )
}

const containerStyles = ({ size }) => ({
  display: 'grid',
  justifyContent: 'center',
  gridTemplateColumns: 'repeat(3, min-content)',
  gap: S.unit(size === 'small' ? 0.75 : 1),
})

const dotStyles = ({ inherit, size }) => ({
  animationName: inherit ? 'opacity-bounce' : 'blue-bounce',
  animationDuration: '600ms',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
  animationFillMode: 'both',
  color: 'inherit',
  background: 'currentColor',
  width: S.unit(size === 'small' ? 0.5 : 1),
  height: S.unit(size === 'small' ? 0.5 : 1),
  borderRadius: '50%',
})

const blueBounceKeyframeStyles = css`
  @keyframes blue-bounce {
    0% {
      color: ${S.colors.neutral(-1)};
      transform: translateY(0rem);
    }
    24% {
      transform: translateY(${S.unit(-1)});
      background: ${S.colors.blue()};
    }
    36% {
      background: ${S.colors.blue()};
      transform: translateY(${S.unit(1)});
    }
    60% {
      background: ${S.colors.neutral(-1)};
      transform: translateY(0rem);
    }
    100% {
      background: ${S.colors.neutral(-1)};
      transform: translateY(0rem);
    }
  }
`

const opacityBounceKeyframeStyles = css`
  @keyframes opacity-bounce {
    0% {
      transform: translateY(0%);
      opacity: 0.5;
    }
    24% {
      transform: translateY(-50%);
      opacity: 1;
    }
    36% {
      transform: translateY(50%);
      opacity: 1;
    }
    60% {
      transform: translateY(0%);
      opacity: 0.5;
    }
    100% {
      transform: translateY(0%);
      opacity: 0.5;
    }
  }
`

export default Loader
