import { useListItem } from '@floating-ui/react'
import * as S from 'apps/cliniko/style'
import DefaultButton from 'components/button'
import * as _ from 'modules/util'
import { forwardRef, useContext } from 'react'
import ActionsContext from './context'

const Item = forwardRef(({ as: Button = DefaultButton, ...props }, refIn) => {
  const actions = useContext(ActionsContext)
  const { ref: itemRef } = useListItem()

  const ref = _.useMergeRefs([itemRef, refIn])

  return (
    <li>
      <Button
        css={itemStyles}
        ref={ref}
        {...props}
        {...actions.getItemProps({
          onClick: _.actions(props.onClick, () => actions.setIsOpen(false)),
        })}
      />
    </li>
  )
})

const itemStyles = {
  display: 'block',
  width: '100%',
  color: S.colors.grey(),
  background: 'white',
  borderRadius: 0,
  ...S.space({ px: 2, py: 1 }),

  [S.media.small]: S.space.px(3),

  '&:hover': {
    background: S.colors.neutral(),
    color: S.colors.grey(),
  },

  '&.is-selected': {
    color: S.colors.blue(-1),
    background: S.colors.blue(12),
    fontWeight: 500,
  },

  '&[data-focus-visible-added]': {
    outline: 'none',
    ...S.insetBorder({ color: S.colors.focus, width: 2 }),
  },

  svg: {
    marginLeft: '0 !important',
    marginRight: S.unit(1) + '!important',
  },
}

export default Item
