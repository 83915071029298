import * as S from 'apps/cliniko/style'
import Actions from 'components/actions'
import Button from 'components/button'
import CircleButton from 'components/circle-button'
import * as Icon from 'components/icon'

const PageActions = ({ children, ...props }) => {
  const isPastBreakpointForm = S.useIsPastBreakpoint('form')
  return isPastBreakpointForm ? (
    <Actions
      as={Button}
      color="neutral-soft"
      label="Actions"
      makeIcon={isOpen => ({ right: isOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown /> })}
      {...props}>
      {children}
    </Actions>
  ) : (
    <Actions as={CircleButton} label="Actions" {...props}>
      {children}
    </Actions>
  )
}

PageActions.Item = Actions.Item
PageActions.Group = Actions.Group

export default PageActions
