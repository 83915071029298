import * as S from 'apps/cliniko/style'
import Button from 'components/button'
import Link from 'components/link'
import { Fragment, useState } from 'react'

const Breadcrumbs = ({ breadcrumbs, className }) => {
  const isBelowBreakpointSmall = S.useIsBelowBreakpoint('small')
  const [hideEllipsis, setHideEllipsis] = useState(false)
  const showEllipsis = isBelowBreakpointSmall && !hideEllipsis && breadcrumbs.length > 2

  return (
    <nav
      aria-label="Breadcrumb links"
      className={className}
      css={containerStyles({ wrap: hideEllipsis })}>
      {!!showEllipsis && (
        <>
          <Button
            aria-label="Show all breadcrumb links"
            css={{ bottom: 1 }}
            link
            onClick={() => setHideEllipsis(true)}>
            …
          </Button>
          <span css={dividerStyles}>/</span>
        </>
      )}
      {breadcrumbs.map(({ href, icon, label }, index) => {
        const isLast = index + 1 === breadcrumbs.length
        return (
          <Fragment key={href}>
            {(!showEllipsis || !!isLast) && (
              <Link css={linkStyles({ truncate: isLast && !hideEllipsis })} href={href} icon={icon}>
                {label}
              </Link>
            )}
            {!showEllipsis && !isLast && <span css={dividerStyles}>/</span>}
          </Fragment>
        )
      })}
    </nav>
  )
}

const containerStyles = ({ wrap }) =>
  wrap
    ? S.flexWrap({ columnGap: S.unit(0.5) })
    : { display: 'flex', alignItems: 'start', gap: S.unit(0.5) }

const linkStyles = ({ truncate }) => [
  truncate ? S.textTruncate : { flex: '0 0 auto' },
  {
    zIndex: 1,
    bottom: S.unit(-0.5),
    fontSize: S.unit(1.5),
    lineHeight: S.unit(2),

    '> svg': {
      bottom: S.rem(-1),
      width: S.unit(1.5),
    },
  },
]

const dividerStyles = {
  color: S.colors.pink(7),
  fontSize: S.unit(1.5),
  position: 'relative',
  bottom: S.unit(-0.75),
  zIndex: 0,
}

export default Breadcrumbs
