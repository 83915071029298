import { forwardRef } from 'react'
import Button from './button'
import Group from './group'
import Item from './item'
import PopupActions from './popup-actions'
import ActionsProvider from './use-actions'

const Actions = forwardRef(
  ({ children, isOpen, label = 'Actions', offset, placement, ...props }, ref) => (
    <ActionsProvider isOpen={isOpen} placement={placement}>
      <Button label={label} ref={ref} {...props} />
      <PopupActions aria-label={label} offset={offset}>
        {children}
      </PopupActions>
    </ActionsProvider>
  )
)

Actions.Item = Item
Actions.Group = Group

export default Actions
