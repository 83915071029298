import * as _ from 'modules/util'
import * as R from 'ramda'
import { em } from './units'

export const minWidthQuery = value => `(width >= ${em(value)})`
export const maxWidthQuery = value => `(width < ${em(value)})`
export const minMaxWidthQuery = ([minWidth, maxWidth]) =>
  `(${em(minWidth)} <= width < ${em(maxWidth)})`

export const makeMediaQueries = ({ allBreakpoints, inBetweenBreakpoints }) => {
  const media = {
    print: '@media print',
    screen: '@media screen',
  }

  const getBreakpoint = R.prop(R.__, allBreakpoints)

  // small, large, etc
  for (const breakpoint in allBreakpoints) {
    media[breakpoint] = `${media.screen} and ${minWidthQuery(getBreakpoint(breakpoint))}`
  }

  // belowSmall, belowLarge, etc
  for (const breakpoint in allBreakpoints) {
    const name = `below${_.capitalizeFirstLetter(breakpoint)}`
    media[name] = `${media.screen} and ${maxWidthQuery(getBreakpoint(breakpoint))}`
  }

  // betweenSmallAndLarge, betweenMediumAndLarge, etc
  const makeInBetweenMediaQuery = ([bp1, bp2]) => {
    const name = `between${_.capitalizeFirstLetter(bp1)}And${_.capitalizeFirstLetter(bp2)}`
    media[name] =
      `${media.screen} and ${minMaxWidthQuery([getBreakpoint(bp1), getBreakpoint(bp2)])}`
  }

  if (inBetweenBreakpoints) inBetweenBreakpoints.forEach(makeInBetweenMediaQuery)

  return media
}
