import Button from 'components/button'
import Page from 'components/page'
import { useMatch } from 'modules/router'

const NotAuthorized = () => {
  const { pathname } = useMatch()

  return (
    <Page>
      <Page.Header heading={`You are not authorized to view “${pathname}“`} />
      <Button color="pink-fill" href="/">
        Return to home
      </Button>
    </Page>
  )
}

export default NotAuthorized
