import * as S from 'apps/cliniko/style'
import Button from 'components/button'
import CircleButton from 'components/circle-button'
import * as Icon from 'components/icon'
import * as _ from 'modules/util'
import { forwardRef } from 'react'

const Notice = forwardRef(
  (
    { actions, children, color = 'fill', icon, isDismissing, onDismiss, type = 'info', ...props },
    ref
  ) => {
    _.throwUnless(
      colors.includes(color),
      `<Notice /> received an invalid color, "${color}". Try one of ${colors.join(', ')} instead`
    )
    const dismissible = !!onDismiss
    const isBanner = type.includes('banner')
    const role = _.cond(
      [['error', 'warning'].includes(type), 'alert'],
      [type === 'success', 'status'],
      [type === 'info', 'complementary']
    )

    return (
      <div
        css={styles({ actions: !!actions, color, dismissible, icon: !!icon, isBanner, type })}
        ref={ref}
        role={role}
        {...props}>
        {!!icon && <div css={iconStyles}>{icon}</div>}
        <div css={childrenAndActionsContainerStyles}>
          <div css={{ position: 'relative', bottom: S.rem(-1), ...S.breakWords }}>{children}</div>
          {!!actions && (
            <div css={S.spaceChildren.mr(1)}>
              {_.castArray(actions).map(action => (
                <Button
                  compact
                  css={S.space({ my: -0.5, mr: dismissible && !isBanner && -0.5 })}
                  color={fillStyles[type].actionButton}
                  key={action.href || action.children}
                  {...action}
                />
              ))}
            </div>
          )}
        </div>
        {!!dismissible && (
          <CircleButton
            compact={isBanner}
            disabled={isDismissing}
            color={fillStyles[type].dismissButton}
            css={S.space({
              mt: isBanner ? -0.5 : -1,
              ml: isBanner ? -0.5 : -1,
              mb: -1,
              mr: _.cond([type === 'info-banner', 0], [type === 'warning-banner', -0.5], [-1]),
            })}
            label="Dismiss"
            icon={<Icon.XSmall color={fillStyles[type].color} {...props} />}
            onClick={onDismiss}
          />
        )}
      </div>
    )
  }
)

const colors = ['fill', 'fill-stroke']

const styles = ({ actions, color, icon, isBanner, type }) => [
  {
    position: 'relative',
    display: 'flex',
    gap: S.unit(2),
    borderRadius: !isBanner && S.borderRadius,
    a: {
      color: 'inherit',
    },
  },
  icon && !actions ? S.space({ pl: 2, pr: 4, py: 2 }) : S.space.p(2),
  isBanner && S.pagePaddingX,
  color.includes('fill') && {
    color: fillStyles[type].color,
    background: fillStyles[type].background,
  },
  color.includes('stroke') && !isBanner && S.insetBorder({ color: fillStyles[type].color }),
]

const childrenAndActionsContainerStyles = [
  {
    flex: '1 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    rowGap: S.unit(1),
    columnGap: S.unit(2),
  },
]

const fillStyles = {
  error: {
    actionButton: 'red-soft-stroke',
    background: S.colors.red(11),
    color: S.colors.red(-1),
    dismissButton: 'red-borderless',
  },
  info: {
    actionButton: 'blue-soft-stroke',
    background: S.colors.blue(12),
    color: S.colors.blue(-1),
    dismissButton: 'blue-borderless',
  },
  'info-banner': {
    actionButton: 'purple-fill',
    background: S.colors.purple(),
    color: 'white',
    dismissButton: 'purple-fill',
  },
  success: {
    actionButton: 'green-soft-stroke',
    background: S.colors.green(11),
    color: S.colors.green(-1),
    dismissButton: 'green-borderless',
  },
  warning: {
    actionButton: 'yellow-soft-stroke',
    background: S.colors.yellow(2),
    color: S.colors.yellow(-12),
    dismissButton: 'yellow-borderless',
  },
  'warning-banner': {
    actionButton: 'yellow-soft-stroke',
    background: S.colors.yellow(2),
    color: S.colors.yellow(-12),
    dismissButton: 'yellow-borderless',
  },
}

const iconStyles = {
  height: S.unit(3),
  '& > svg': {
    height: '100%',
    bottom: S.rem(1),
  },
}

export default Notice
