import { FloatingFocusManager, FloatingList, useTransitionStyles } from '@floating-ui/react'
import * as S from 'apps/cliniko/style'
import Button from 'components/button'
import Overlay from 'components/overlay'
import Portal from 'components/portal'
import * as _ from 'modules/util'
import * as R from 'ramda'
import { forwardRef, useContext } from 'react'
import ActionsContext from './context'

const PopupActions = forwardRef(({ children, offset, ...propsIn }, refIn) => {
  const actions = useContext(ActionsContext)
  const ref = _.useMergeRefs([actions.refs.setFloating, refIn])
  const isPastBreakpointSmall = S.useIsPastBreakpoint('small')

  const { isMounted, styles } = useTransitionStyles(actions.context, {
    duration: { open: 200, close: 150 },
    initial: ({ side }) => ({
      opacity: 0,
      transform: _.cond(
        [side === 'top', `translateY(${S.unit(1)})`],
        [side === 'bottom', `translateY(${S.unit(-1)}`],
        [side === 'left', `translateX(${S.unit(1)})`],
        [side === 'right', `translateX(${S.unit(-1)}`]
      ),
    }),
  })

  const style = R.mergeAll([
    isPastBreakpointSmall && {
      position: actions.strategy,
      top: (offset?.y ?? 0) + (actions.y ?? 0),
      left: (offset?.x ?? 0) + (actions.x ?? 0),
      visibility: actions.x ? 'visible' : 'hidden',
    },
    styles,
  ])

  const props = {
    ref,
    style,
    ...propsIn,
    ...actions.getFloatingProps(propsIn),
  }

  const popup = (
    <FloatingFocusManager context={actions.context} initialFocus={-1} modal={false}>
      <FloatingList elementsRef={actions.elementsRef} labelsRef={actions.labelsRef}>
        <div css={[popupStyles, { zIndex: S.zIndex('app', 'popup') }]} {...props}>
          <ul>{children}</ul>
        </div>
        {!isPastBreakpointSmall && (
          <Button
            color="grey-borderless"
            css={cancelButtonStyles}
            label="Cancel"
            onClick={() => actions.setIsOpen(false)}
          />
        )}
      </FloatingList>
    </FloatingFocusManager>
  )

  return _.cond(
    [isPastBreakpointSmall && isMounted, <Portal>{popup}</Portal>],
    [
      !isPastBreakpointSmall,
      <Overlay css={S.breakpoint.belowSmall({ alignSelf: 'end' })} when={isMounted}>
        {popup}
      </Overlay>,
    ]
  )
})

const popupStyles = [
  S.scrollbar,
  {
    background: 'white',
    overflow: 'auto',
    borderRadius: S.borderRadius,
    paddingTop: S.rem(S.units(1) - 1),
    paddingBottom: S.rem(S.units(1) - 1),
    minHeight: S.unit(7),
    border: `1px solid ${S.colors.neutral(-5)}`,
    boxShadow: S.shadow,
    outline: 'none',

    [S.media.belowSmall]: {
      border: 'none',
      borderRadius: S.unit(1),
      ...S.space.mx(1),
      maxHeight: `calc(100vh - ${S.unit(10)}) !important`,
    },
  },
]

const cancelButtonStyles = {
  background: 'white',
  width: `calc(100% - ${S.unit(2)})`,
  borderRadius: S.borderRadius,
  textAlign: 'center',
  ...S.space({ py: 2, m: 1 }),
}

export default PopupActions
