import * as S from 'apps/cliniko/style'
import Breadcrumbs from 'components/breadcrumbs'
import Heading from 'components/heading'
import * as _ from 'modules/util'
import { useEffect } from 'react'

const PageHeader = ({
  actions,
  alerts,
  border,
  breadcrumbs,
  children,
  heading,
  title = heading,
  ...props
}) => {
  useEffect(() => {
    if (_.isBlank(title)) return
    document.title = title
  }, [title])

  return (
    <header css={!!border && borderStyles} {...props}>
      {alerts}
      <div css={!!actions && S.grid('1fr auto')}>
        <div css={{ overflow: 'hidden', paddingLeft: S.rem(6), marginLeft: S.rem(-6) }}>
          {!!breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} css={S.space.mb(1)} />}
          <Heading css={[S.space.pt(1), S.breakWords]} level={2} styleLevel={1}>
            {heading}
          </Heading>
        </div>
        {!!actions && <div css={actionsContainerStyles}>{actions}</div>}
      </div>
      {!!children && <div css={S.space.mt(1)}>{children}</div>}
    </header>
  )
}

const borderStyles = {
  boxShadow: `inset 0 ${S.unit(-1)} 0 0 ${S.colors.neutral(1)}`,
  ...S.space.pb(4),
}

const actionsContainerStyles = {
  display: 'grid',
  gridAutoColumns: 'max-content',
  gridAutoFlow: 'column',
  gap: S.unit(1),
}

export default PageHeader
