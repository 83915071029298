const getOffsetFromList = (offset, list) => {
  if (offset > list.length) {
    throw new Error(`The offset ${offset} is too large. Try a value ${list.length} or smaller`)
  }
  return list[offset - 1]
}

export const getColor = ({ default: defaultColor, darken, lighten }) => {
  const getColorWithOffset = (offset = 0) => {
    if (offset === 0) return defaultColor
    if (offset < 0) return getOffsetFromList(Math.abs(offset), darken)
    return getOffsetFromList(offset, lighten)
  }
  getColorWithOffset.range = { darken: darken.length, lighten: lighten.length }
  return getColorWithOffset
}

// These are also used in app/assets/stylesheets/application-redesign/_color-variables.sass
const colors = {
  blue: getColor({
    default: '#007FA3',
    darken: ['#006B89', '#005770', '#004356', '#00303D', '#001C23', '#00080A'],
    lighten: [
      '#0093BC',
      '#00A7D6',
      '#00BBEF',
      '#0AC9FF',
      '#23CFFF',
      '#3DD4FF',
      '#56DAFF',
      '#70DFFF',
      '#89E5FF',
      '#A3EBFF',
      '#BCF0FF',
      '#D6F6FF',
      '#EFFCFF',
    ],
  }),
  pink: getColor({
    default: '#CC3057',
    darken: [
      '#B72B4E',
      '#A32645',
      '#8E213D',
      '#791D34',
      '#65182B',
      '#501322',
      '#3B0E19',
      '#270911',
      '#120408',
    ],
    lighten: [
      '#D34367',
      '#D85777',
      '#DC6C88',
      '#E18199',
      '#E695AA',
      '#EBAABA',
      '#F0BFCB',
      '#F5D3DC',
      '#FAE8EC',
      '#FEFDFD',
    ],
  }),
  purple: getColor({
    default: '#4B384C',
    darken: ['#3D2D3D', '#2E222F', '#201820', '#110D11', '#030203'],
    lighten: [
      '#59435B',
      '#684E69',
      '#765878',
      '#856387',
      '#936F95',
      '#9E7DA0',
      '#A98CAA',
      '#B49BB5',
      '#BFA9C0',
      '#CAB8CB',
      '#D5C7D6',
      '#E0D6E0',
      '#EBE4EB',
      '#F6F3F6',
    ],
  }),
  grey: getColor({
    default: '#333333',
    darken: ['#262626', '#1A1A1A', '#0D0D0D', '#000000'],
    lighten: [
      '#404040',
      '#4D4D4D',
      '#595959',
      '#666666',
      '#737373',
      '#808080',
      '#8C8C8C',
      '#999999',
      '#A6A6A6',
      '#B3B3B3',
      '#BFBFBF',
      '#CCCCCC',
      '#D9D9D9',
      '#E6E6E6',
      '#F2F2F2',
    ],
  }),
  neutral: getColor({
    default: '#E7EDF0',
    darken: [
      '#D7E1E6',
      '#C8D5DC',
      '#B8CAD3',
      '#A8BEC9',
      '#99B2BF',
      '#89A6B5',
      '#799BAB',
      '#698FA2',
      '#5D8295',
      '#537485',
      '#496775',
      '#3F5966',
      '#364B56',
      '#2C3D46',
      '#223036',
      '#182227',
      '#0E1417',
      '#050607',
    ],
    lighten: ['#F7F9FA'],
  }),
  red: getColor({
    default: '#D00400',
    darken: [
      '#B60400',
      '#9D0300',
      '#830300',
      '#6A0200',
      '#500200',
      '#370100',
      '#1D0100',
      '#040000',
    ],
    lighten: [
      '#E90400',
      '#FF0904',
      '#FF221D',
      '#FF3B37',
      '#FF5450',
      '#FF6D6A',
      '#FF8683',
      '#FF9F9D',
      '#FFB8B6',
      '#FFD1D0',
      '#FFEAE9',
    ],
  }),
  yellow: getColor({
    default: '#F8E08E',
    darken: [
      '#F7D976',
      '#F5D35E',
      '#F4CC46',
      '#F2C62E',
      '#F1BF16',
      '#DFB00E',
      '#C79D0C',
      '#AF8A0B',
      '#977709',
      '#7F6408',
      '#675106',
      '#4F3E05',
      '#372B03',
      '#1F1802',
      '#070600',
    ],
    lighten: ['#F9E7A6', '#FBEDBE', '#FCF4D6', '#FEFAEE'],
  }),
  green: getColor({
    default: '#318351',
    darken: ['#2A7046', '#235E3A', '#1C4B2F', '#153923', '#0E2618', '#07140C', '#000101'],
    lighten: [
      '#38965C',
      '#3FA868',
      '#47BA74',
      '#59C182',
      '#6CC890',
      '#7ECF9E',
      '#91D6AC',
      '#A3DDBA',
      '#B6E4C8',
      '#C8EBD6',
      '#DBF2E4',
      '#EEF8F2',
    ],
  }),
  lavender: getColor({
    default: '#E2DAFD',
    darken: [
      '#D2C7F5',
      '#C0B1F2',
      '#AE9BEE',
      '#9C86EA',
      '#8B6FE6',
      '#7A5AE2',
      '#6844DF',
      '#572FDA',
      '#4B24CC',
      '#4320B6',
      '#3B1CA0',
    ],
    lighten: [
      '#E4DDFD',
      '#E8E1FD',
      '#EBE5FE',
      '#EEE9FE',
      '#F2EEFE',
      '#F5F2FE',
      '#F8F6FF',
      '#FCFBFF',
    ],
  }),
  orange: getColor({
    default: '#FFA38B',
    darken: [
      '#FB8D72',
      '#FB7756',
      '#FA613B',
      '#F94A20',
      '#F63606',
      '#DA3006',
      '#BF2A05',
      '#A42404',
      '#891E03',
      '#6D1803',
      '#491002',
    ],
    lighten: [
      '#FFAC97',
      '#FFB6A4',
      '#FFC0B1',
      '#FFCBBE',
      '#FFD5CB',
      '#FFE0D8',
      '#FFEAE5',
      '#FFF5F2',
    ],
  }),
}

colors.focus = colors.neutral(-10)

export default colors
