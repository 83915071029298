import {
  useLoaderData as useLoaderDataFromReactRouter,
  useMatch as useMatchFromReactRouter,
  useParams as useParamsFromReactRouter,
  useSearch as useSearchFromReactRouter,
} from '@tanstack/react-router'

export * from './helpers'
export { confirmRoutePromptMessage, routePrompt } from './route-prompt'
export { Outlet, useMatchRoute, useNavigate } from '@tanstack/react-router'

export const useLoaderData = props => useLoaderDataFromReactRouter({ strict: false, ...props })
export const useMatch = props => useMatchFromReactRouter({ strict: false, ...props })
export const useParams = props => useParamsFromReactRouter({ strict: false, ...props })
export const useSearch = props => useSearchFromReactRouter({ strict: false, ...props })
